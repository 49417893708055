/* eslint-disable no-console */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { createSearchParams, useLocation, useSearchParams, useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import KeyIcon from '@mui/icons-material/Key';
import SettingsInputHdmiIcon from '@mui/icons-material/SettingsInputHdmi';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import getI18nService from '../../i18n/i18n';
import { Context } from '../../store/Store';
import userService from '../../api/UserService';
import Logo from '../includes/Logo';

import Wireless from './Wireless';
import Wired from './Wired';
import { typeAll, typeWired, typeWireless, getAllDevices, logoutUser } from './Utils';
import UserDevices from './UserDevices';

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '50%',
  // width: 75,
  // height: 75,
  border: `1px solid ${theme.palette.grey['500']}`,
  display: 'flex',
}));

const i18n = getI18nService();

const Register = props => {
  const [state, dispatch] = useContext(Context);
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let location = useLocation();
  const [typeQuery, setTypeQuery] = useState(searchParams.get('type'));
  const { tenantToken, sessionId } = useParams();
  const [allDevices, setAllDevices] = useState([]);
  const { api } = state;
  const userInfo = api[userService.GetUserInfo.name];

  const { ui } = state;
  const { customization } = ui;
  const { allowWired, allowWireless } = ui;

  useEffect(() => {
    const newType = searchParams.get('type');
    if (newType !== typeQuery) {
      setTypeQuery(newType);
    }
  }, [location]);

  const fetchAllDevices = async () => {
    const result = await getAllDevices(userInfo);
    if (result) {
      setAllDevices(result);
    }
  };

  useEffect(() => {
    if (userInfo) {
      fetchAllDevices();
    }
  }, [userInfo]);

  console.log(typeQuery);
  // let query = useQuery();
  const getQuery = () => {
    console.log(searchParams.get('type'));
    setTypeQuery(searchParams.get('type'));
  };
  const setQuery = tab => {
    setTypeQuery(tab);
    setSearchParams(createSearchParams({ type: tab }));
  };

  const logout = async event => {
    const resp = await logoutUser(userInfo, state, dispatch);
    if (resp) {
      const type = 'logged_out';
      navigate({
        pathname: '/self-register/session/register/startover',
        search: createSearchParams({
          type,
        }).toString(),
      });
    }
  };

  return (
    <>
      {customization && (
        <Box
          id="test_self_register_register"
          sx={{
            minWidth: 1280,
            height: '90%',
            borderRadius: 4,
            mx: 'auto',
            mt: 8,
            mb: 4,
            opacity: 0.8,
          }}
        >
          <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
            <Grid xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'flec-start',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {customization && (
                    <Logo width={50} height={30} data={customization.logo.data} css={customization.logo.css} />
                  )}
                  <Box
                    sx={{
                      textAlign: 'center',
                      p: 1,
                      fontSize: 'h4.fontSize',
                      color: customization.palettes[0].background.paper,
                    }}
                  >{`Nilesecure`}</Box>
                </Box>
              </Box>
              <Typography
                align="center"
                variant="h5"
                sx={{ color: customization.palettes[0].background.paper }}
              >{`${i18n.t('Vocabulary.welcome')} ${userInfo ? userInfo.user_name : ''}!`}</Typography>
            </Grid>
            <Grid xs={6}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                {allowWireless && (
                  <Item onClick={() => setQuery(typeWireless)}>
                    <Tooltip title={i18n.t('SelfRegister.manageKeys')}>
                      <IconButton
                        aria-label={i18n.t('SelfRegister.manageKeys')}
                        id="test_self_register_manageKeysBtn"
                        sx={{
                          ...(typeQuery === typeWireless && {
                            color: customization.palettes[0].background.accent || theme.palette.primary.main,
                          }),
                        }}
                      >
                        <KeyIcon fontSize="large" style={{ fontSize: 42 }} />
                      </IconButton>
                    </Tooltip>
                  </Item>
                )}
                {allowWired && (
                  <Item onClick={() => setQuery(typeWired)}>
                    <Tooltip title={i18n.t('SelfRegister.manageWiredDevices')}>
                      <IconButton
                        id="test_self_register_wiredDevicesBtn"
                        aria-label={i18n.t('SelfRegister.manageWiredDevices')}
                        sx={{
                          ...(typeQuery === typeWired && {
                            color: customization.palettes[0].background.accent || theme.palette.primary.main,
                          }),
                        }}
                      >
                        <SettingsInputHdmiIcon style={{ fontSize: 42 }} />
                      </IconButton>
                    </Tooltip>
                  </Item>
                )}
                {(allowWireless || allowWired) && (
                  <Item onClick={() => setQuery(typeAll)}>
                    <Tooltip title={i18n.t('SelfRegister.manageAllDevices')}>
                      <IconButton
                        id="test_self_register_allDevicesBtn"
                        aria-label={i18n.t('SelfRegister.manageAllDevices')}
                        sx={{
                          ...(typeQuery === typeAll && {
                            color: customization.palettes[0].background.accent || theme.palette.primary.main,
                          }),
                        }}
                      >
                        <AllInclusiveIcon style={{ fontSize: 42 }} />
                      </IconButton>
                    </Tooltip>
                  </Item>
                )}
                {/* <Item onClick={logout}> */}
                <Tooltip title={i18n.t('SelfRegister.logoutMessage')}>
                  <IconButton
                    onClick={logout}
                    id="test_self_register_logoutBtn"
                    aria-label={i18n.t('SelfRegister.logoutMessage')}
                    // sx={{ color: theme.palette.primary.main }}
                  >
                    <LogoutIcon style={{ fontSize: 42 }} />
                  </IconButton>
                </Tooltip>
                {/* </Item> */}
              </Stack>
            </Grid>
          </Grid>
          <Box sx={{ textAlign: 'center', p: 2, height: '100%' }}>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Paper sx={{ m: 2, p: 1, overflow: 'auto' }}>
                  {allowWireless && typeQuery === typeWireless && userInfo && (
                    <Wireless userInfo={userInfo} deviceList={allDevices} onAddRemoveDevice={fetchAllDevices} />
                  )}
                  {allowWired && typeQuery === typeWired && userInfo && (
                    <Wired userInfo={userInfo} deviceList={allDevices} onAddRemoveDevice={fetchAllDevices} />
                  )}
                  {typeQuery === typeAll && userInfo && (
                    <UserDevices userInfo={userInfo} deviceList={allDevices} onDeviceDelete={fetchAllDevices} />
                  )}
                </Paper>
                {userInfo && (
                  <Typography variant="body2">
                    {`Allowed limits: ${userInfo.limits.credentials} keys, ${userInfo.limits.devices} devices`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
          {/* )} */}
        </Box>
      )}
    </>
  );
};

Register.propTypes = {
  // userInfo: PropTypes.object.isRequired,
};

export default Register;
